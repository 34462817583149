// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-sanity-post-slug-current-jsx": () => import("./../../../src/pages/blog/{sanityPost.slug__current}.jsx" /* webpackChunkName: "component---src-pages-blog-sanity-post-slug-current-jsx" */),
  "component---src-pages-businesses-we-work-with-js": () => import("./../../../src/pages/businesses-we-work-with.js" /* webpackChunkName: "component---src-pages-businesses-we-work-with-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-furniture-removal-index-js": () => import("./../../../src/pages/furniture-removal/index.js" /* webpackChunkName: "component---src-pages-furniture-removal-index-js" */),
  "component---src-pages-furniture-removal-success-js": () => import("./../../../src/pages/furniture-removal/success.js" /* webpackChunkName: "component---src-pages-furniture-removal-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/meet-the-team.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */),
  "component---src-pages-packing-materials-js": () => import("./../../../src/pages/packing-materials.js" /* webpackChunkName: "component---src-pages-packing-materials-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sanity-page-slug-current-jsx": () => import("./../../../src/pages/{sanityPage.slug__current}.jsx" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-jsx" */),
  "component---src-pages-shipping-containers-for-sale-js": () => import("./../../../src/pages/shipping-containers-for-sale.js" /* webpackChunkName: "component---src-pages-shipping-containers-for-sale-js" */),
  "component---src-pages-storage-solutions-index-js": () => import("./../../../src/pages/storage-solutions/index.js" /* webpackChunkName: "component---src-pages-storage-solutions-index-js" */),
  "component---src-pages-storage-solutions-success-js": () => import("./../../../src/pages/storage-solutions/success.js" /* webpackChunkName: "component---src-pages-storage-solutions-success-js" */),
  "component---src-pages-tncs-js": () => import("./../../../src/pages/tncs.js" /* webpackChunkName: "component---src-pages-tncs-js" */),
  "component---src-pages-why-choose-us-js": () => import("./../../../src/pages/why-choose-us.js" /* webpackChunkName: "component---src-pages-why-choose-us-js" */)
}

